import React, { useRef, useEffect, useMemo } from 'react';
import './Contact.css';
import { useTranslation } from 'react-i18next';
import { waitFor } from '../utils/asyncTools';

function ContactPage() {
    const { t, i18n } = useTranslation();

    const formIds = useMemo(
        () => ({
            'pl-PL': 'c4316160-0f95-4a57-b3c4-749888d48734',
            'en-EN': 'ad8790f9-7b4b-4c0d-a016-05f259fd7183',
            'de-DE': '1b382bf5-72e5-420f-af53-a39d6dc4649a',
        }),
        []
    );

    //let lastLang = null;
    const lastLangRef = useRef(null);

    useEffect(() => {
        const loadHubspot = async () => {
            const existingScript = document.getElementById('hubspot-script');

            if (!existingScript) {
                const script = document.createElement('script');
                script.id = 'hubspot-script';
                script.src = 'https://js.hsforms.net/forms/v2.js';
                document.head.appendChild(script);
            }

            await waitFor(() => window.hbspt);

            setTimeout(() => {
                if (window.hbspt) {
                    if (lastLangRef.current !== i18n.language) {
                        lastLangRef.current = i18n.language;
                        console.log('HubSpot loaded');
                        window.hbspt.forms.create({
                            region: 'eu1',
                            portalId: '25834864',
                            formId: formIds[i18n.language], //'ad8790f9-7b4b-4c0d-a016-05f259fd7183',
                            class: 'hubSpot-form',
                            target: '#hubspotForm',
                        });
                    }
                } else {
                    console.log('HubSpot not loaded');
                    const text = document.createElement('div');
                    text.id = 'hubspot-err-text';
                    text.textContent = 'HubSpot form not loaded. Please try refreshing the page.';
                    const formTemp = document.getElementById('hubspotForm');
                    const errText = document.getElementById('hubspot-err-text');
                    if (!errText) formTemp.appendChild(text);
                }
            }, 100);
        };

        i18n.on('languageChanged', loadHubspot);

        loadHubspot();

        return () => {
            i18n.off('languageChanged', loadHubspot);
        };
    }, [i18n, formIds]);

    return (
        <>
            <div className="contact-container">
                <div className="left-col">
                    <h2 className="heading"> {t('contact.title')} </h2>
                    <p className="subheading">
                        {t('contact.subtitle')}
                        <br />
                        <br />
                        {t('contact.subtitle2')}
                    </p>
                </div>
                <div className="right-col">
                    <div id="hubspotForm"></div>
                </div>

                {/* <form className="right-col hubSpot-form" id="hubspotForm">
                        <div className="form-grup-wrapper">
                            <div className="form-group">
                                <input className="input formField__input" placeholder="Company" type="text" name="companyName" />
                            </div>
                            <div className="form-group">
                                <input className="input" placeholder="Phone" name="phone" />
                            </div>
                        </div>
                        <div className="form-group">
                            <input className="input" type="email" placeholder="E-mail" name="email" />
                        </div>
                        <div className="products">
                            <p>Products:</p>
                            <Checkbox label="Auto Space Plan Generation" name="genspace" />
                            <Checkbox label="Auto Fit Out Valuation" name="budget-estimate" />
                            <Checkbox label="Lease Process Management" name="leasing" />
                            <Checkbox label="Portfolio Management" name="management" />
                        </div>
                        <div className="form-group">
                            <textarea className="textarea" placeholder="Your message (Optional)" />
                        </div>
                        <div className="consent">
                            <Checkbox label="I agree to receive other communications from SQUARE PLAN." name="communications" />
                            <Checkbox
                                label="I consent to the storage and processing of my personal data by Spantium 360 Sp. z o. o."
                                name="consents"
                            />
                        </div>
                        <button className="submit-button form__send" data-attr-t data-attr-t-interpolate type="submit">
                            Submit
                        </button>
                    </form> */}
            </div>
        </>
    );
}

export default ContactPage;
