import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import './Careers.css';
import { NavLink } from 'react-router-dom';
import arrowRight from '../assets/img/arrow-grey-right.svg';
import { useTranslation } from 'react-i18next';
import PopUp from '../components/PopUp';

function JobPosition({ title, children }) {
    const popUpRef = useRef(null);
    const { t } = useTranslation();
    const [isPopUpVisible, setPopUpVisible] = useState(false);

    const handlePopUpClick = (event) => {
        console.log(`PopUp clicked: ${event}`);
    };

    const handleClosePopUp = () => {
        console.log('ccclose');
        setPopUpVisible(false);
    };

    const showPopUp = () => {
        console.log('show');
        setPopUpVisible(true);
        //  popUpRef.current.show();
    };

    useEffect(() => {
        console.log('xxx', { isPopUpVisible, pop: popUpRef.current });
        if (isPopUpVisible && popUpRef.current) {
            popUpRef.current.show();
        }
    }, [isPopUpVisible]);

    return (
        <div className="job-position" onClick={showPopUp}>
            <div className="job-title">{t(title)}</div>
            <img loading="lazy" src={arrowRight} alt={title} className="job-image" />

            <PopUp
                ref={popUpRef}
                size="medium"
                title=""
                icon="/path/to/icon.png"
                iconColor="red"
                noPadding={false}
                onPopUpClick={handlePopUpClick}
                onClosePopUp={handleClosePopUp}
            >
                {children}
            </PopUp>
        </div>
    );
}

function CareersPage() {
    const { t } = useTranslation();

    const jobPositions = [
        {
            title: 'careers.positions.0.name',
            imageSrc: arrowRight,
        },
        {
            title: 'careers.positions.1.name',
            imageSrc: arrowRight,
        },
        {
            title: 'careers.positions.2.name',
            imageSrc: arrowRight,
        },
    ];

    return (
        <>
            <section className="careers-container">
                <section className="careers-hero-section">
                    <div className="careers-hero-content">
                        <div className="hero-info">
                            <div className="hero-text-container">
                                <h1 className="careers-hero-title">{t('careers.title')}</h1>
                                <p className="careers-hero-description">{t('careers.info')}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="job-pic">{t('careers.joinUs')}</div>
                <section className="job-section">
                    <div className="job-container">
                        <div className="job-list">
                            <div className="job-list-inner">
                                <div className="job-list-content">
                                    <header className="job-content">
                                        <h2 className="job-header">{t('careers.positions')}</h2>
                                        <div className="job-position-container">
                                            {/* {jobPositions.map((position, index) => ( */}
                                            <JobPosition title="Customer Success Specialist (Architect with AutoCAD Skills)">
                                                <h3>Job Title: Customer Success Specialist (Architect with AutoCAD Skills)</h3>
                                                <div class="jobPositionSection">
                                                    Company: <b>SquarePlan</b>
                                                </div>
                                                <div class="jobPositionSection">
                                                    Location: <b>Warsaw, Poland (but can be fully remote)</b>
                                                </div>
                                                <div>
                                                    <div class="jobPositionSection">About Us:</div>
                                                    <div>
                                                        At SquarePlan we’re revolutionizing the commercial real estate industry with
                                                        cutting-edge technology.. As a rapidly growing startup, we’re looking for passionate
                                                        and talented individuals to join our team and help drive our mission forward.
                                                        SquarePlan is an AI+Algorithm company that automates space planning and estimation
                                                        of fitout cost estimation. We also support project communication - uploaded 2D dwg
                                                        file becomes a 3D one and includes VR and AR versions of the space within seconds.
                                                        We’re very proud to be used by{' '}
                                                        <a
                                                            href="https://www.linkedin.com/company/strabag-real-estate/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            STRABAG Real Estate
                                                        </a>
                                                        ,{' '}
                                                        <a
                                                            href="https://www.linkedin.com/company/mapletree/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Mapletree
                                                        </a>
                                                        ,{' '}
                                                        <a
                                                            href="https://www.linkedin.com/company/vastint/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Vastint
                                                        </a>
                                                        ,{' '}
                                                        <a
                                                            href="https://www.linkedin.com/company/ghelamco/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Ghelamco
                                                        </a>
                                                        ,
                                                        <a
                                                            href="https://www.linkedin.com/company/tetris_fit-out/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Tétris
                                                        </a>
                                                        ,{' '}
                                                        <a
                                                            href="https://www.linkedin.com/company/m-moser-associates/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            M Moser Associates
                                                        </a>{' '}
                                                        and quite a few other large players.
                                                    </div>
                                                </div>
                                                <div class="jobPositionSection">Position Overview:</div>
                                                <div>
                                                    We are seeking a highly motivated and skilled Customer Success Specialist who is also a
                                                    trained architect with expertise in AutoCAD. The ideal candidate will possess a unique
                                                    blend of architectural knowledge and customer service excellence, ensuring our clients
                                                    receive the highest level of support and satisfaction. This role is pivotal in helping
                                                    our customers fully utilize our platform to achieve their project goals. Key
                                                    Responsibilities:
                                                </div>
                                                <ul>
                                                    <li>
                                                        Act as the primary point of contact for our clients, providing exceptional customer
                                                        service and support.
                                                    </li>
                                                    <li>
                                                        Understand and address client needs, ensuring they are able to effectively use our
                                                        PropTech solutions.
                                                    </li>
                                                    <li>
                                                        Utilize your architectural training to provide expert advice and solutions, helping
                                                        clients integrate our technology into their projects.
                                                    </li>
                                                    <li>
                                                        Conduct product demonstrations, training sessions, and webinars to educate clients
                                                        on the features and benefits of our platform.
                                                    </li>
                                                    <li>
                                                        Troubleshoot and resolve any issues related to the use of our software, particularly
                                                        in relation to AutoCAD integration.
                                                    </li>
                                                    <li>
                                                        Collaborate with the product development team to relay client feedback and
                                                        contribute to the ongoing improvement of our platform.
                                                    </li>
                                                    <li>
                                                        Develop and maintain strong relationships with clients, ensuring their long-term
                                                        success and satisfaction.
                                                    </li>
                                                    <li>
                                                        Create and update documentation, tutorials, and support materials to assist clients
                                                        in using our products effectively.
                                                    </li>
                                                </ul>
                                                <div class="jobPositionSection">Qualifications:</div>
                                                <ul>
                                                    <li>Bachelor’s or Master’s degree in Architecture or a related field.</li>
                                                    <li>
                                                        Proficiency in AutoCAD is mandatory; experience with other design software is a
                                                        plus.
                                                    </li>
                                                    <li>
                                                        Previous experience in a customer success, support, or client-facing role is highly
                                                        desirable.
                                                    </li>
                                                    <li>Strong understanding of architectural and construction processes.</li>
                                                    <li>Excellent communication and interpersonal skills.</li>
                                                    <li>Ability to explain complex technical concepts in a clear and concise manner.</li>
                                                    <li>Problem-solving mindset with a proactive approach to addressing client needs.</li>
                                                    <li>Ability to work independently and as part of a collaborative team.</li>
                                                    <li>Passion for technology and innovation within the PropTech industry.</li>
                                                </ul>
                                                <div class="jobPositionSection">What We Offer:</div>
                                                <ul>
                                                    <li>Competitive salary and benefits package.</li>
                                                    <li>Opportunity to work in a dynamic and innovative startup environment.</li>
                                                    <li>Career growth and development opportunities (we grow 2x every quarter)</li>
                                                    <li>Flexible work arrangements, including remote work options.</li>
                                                    <li>A supportive and collaborative team culture.</li>
                                                </ul>
                                                <div class="jobPositionSection">How to Apply:</div>
                                                If you are passionate about architecture and technology, and have a knack for helping
                                                clients succeed, we would love to hear from you! Please send your resume and a cover letter
                                                explaining why you are the perfect fit for this role to{' '}
                                                <a href="mailto:maciej.markowski@squareplan.io?subject=Job Position: Customer Success Specialist">
                                                    maciej.markowski@squareplan.io
                                                </a>
                                                <div>
                                                    Join us at SquarePlan and be a part of transforming the future of commercial real
                                                    estate!
                                                </div>
                                            </JobPosition>
                                            {/* ))} */}
                                        </div>
                                    </header>
                                    <div className="contact-section">
                                        <p className="contact-text">{t('careers.notFound')}</p>
                                        <NavLink to="/contact" className="careers-nav-link">
                                            <div className="contact-btn">{t('careers.contact')}</div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="careers-image-section">
                            {/* <img loading="lazy" src={careersHero} alt="Company team working together" className="careers-hero-image" /> */}
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}

export default CareersPage;
