import PropTypes from 'prop-types';
import React from 'react';
import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import './BookBtn.css';

function BookBtn({ stateProp, className, noClick, buttonText }) {
    function reducer(state, action) {
        if (state.state === 'primary') {
            switch (action) {
                case 'click':
                    return {
                        state: 'pressed',
                    };

                case 'mouse_enter':
                    return {
                        state: 'hover',
                    };
                default:
                    return state;
            }
        }

        if (state.state === 'hover') {
            switch (action) {
                case 'mouse_leave':
                    return {
                        state: 'primary',
                    };
                default:
                    return state;
            }
        }

        return state;
    }

    BookBtn.propTypes = {
        stateProp: PropTypes.oneOf(['primary', 'disabled', 'pressed', 'hover']),
    };

    const [state, dispatch] = useReducer(reducer, {
        state: stateProp || 'primary',
    });

    const navigate = useNavigate();

    return (
        <button
            className={`button ${state.state} ${className}`}
            onMouseEnter={() => {
                dispatch('mouse_enter');
            }}
            onMouseLeave={() => {
                dispatch('mouse_leave');
            }}
            onClick={() => {
                if (state.state === 'disabled' || noClick) {
                    return;
                }
                dispatch('click');
                navigate('/contact');
            }}
        >
            <div className="text-wrapper">{buttonText}</div>
        </button>
    );
}

export default BookBtn;
