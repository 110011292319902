import React from 'react';
import BookBtn from './BookBtn';
import './HeroSection.css';

function HeroSection({ title, description, buttonText, heroImageSrc }) {
    return (
        <div className="hero-image-container">
            <div className="hero-image-left">
                <section className="hero-section">
                    <div className="text-content">
                        <h2 className="hero-title">{title}</h2>
                        <p className="hero-description">{description}</p>
                        <BookBtn buttonText={buttonText} stateProp="primary" />
                    </div>
                </section>
            </div>
            <div className="hero-image-right">
                <img src={heroImageSrc} alt="Main visual representation" />
            </div>
        </div>
    );
}

export default HeroSection;
