import * as React from 'react';
import './Team.css';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import teamPhotoMember0 from '../assets/img/team-photo-member-0.png';
import teamPhotoMember1 from '../assets/img/team-photo-member-1.png';
import teamPhotoMember2 from '../assets/img/team-photo-member-2.png';
//import teamIconIn from '../assets/img/team-icon-in.png';
import iconLinkedIn from '../assets/img/logo_linkedin.svg';
import iconX from '../assets/img/logo_x.svg';

const TeamMemberCard = ({
    imgSrc,
    altText,
    name,
    title,
    description,
    partnerImgSrc,
    partnerImg2Src,
    partnerAltText,
    partnerImgLink,
    partnerImg2Link,
}) => {
    const { t } = useTranslation();
    return (
        <article className="team-member-card">
            <figure>
                <figcaption>
                    <header className="team-member-header">
                        <img loading="lazy" src={imgSrc} alt={altText} className="profile-img" />
                        <div className="team-member-info">
                            <h3 className="team-member-name">{t(name)}</h3>
                            <h4 className="team-member-title">{t(title)}</h4>
                        </div>
                    </header>
                    <p className="team-member-description">{t(description)}</p>

                    <div className="partner-logo" tabIndex="0" role="button">
                        {partnerImgSrc && (
                            <a href={partnerImgLink} target="_blank" rel="noreferrer">
                                <img loading="lazy" src={partnerImgSrc} alt={partnerAltText} className="partner-img" />
                            </a>
                        )}
                        {partnerImg2Src && (
                            <a href={partnerImg2Link} target="_blank" rel="noreferrer">
                                <img loading="lazy" src={partnerImg2Src} alt={partnerAltText} className="partner-img" />
                            </a>
                        )}
                        <div className="partner-placeholder"> </div>
                    </div>
                </figcaption>
            </figure>
        </article>
    );
};

function TeamPage() {
    const { t } = useTranslation();
    return (
        <>
            <div className="team-main-container">
                <div className="team-inner-container">
                    <section className="intro-section">
                        <div className="intro-container">
                            <header className="intro-header">
                                <h1 className="intro-title">{t('team.title')}</h1>
                                <p className="intro-description">{t('team.info')}</p>
                            </header>
                        </div>
                    </section>
                    <main className="team-section">
                        <div className="team-container">
                            <TeamMemberCard
                                imgSrc={teamPhotoMember0}
                                altText="team.member.0.name"
                                name="team.member.0.name"
                                title="team.member.0.title"
                                description="team.member.0.description"
                                partnerAltText="Partner Logo"
                            />
                            <TeamMemberCard
                                imgSrc={teamPhotoMember1}
                                altText="team.member.1.name"
                                name="team.member.1.name"
                                title="team.member.1.title"
                                description="team.member.1.description"
                                partnerImgSrc={iconLinkedIn}
                                partnerImgLink={'https://www.linkedin.com/in/maciej-markowski/'}
                                partnerImg2Src={iconX}
                                partnerImg2Link={'https://x.com/MMWorkplace'}
                                partnerAltText="Partner Logo"
                            />
                            <TeamMemberCard
                                imgSrc={teamPhotoMember2}
                                altText="team.member.2.name"
                                name="team.member.2.name"
                                title="team.member.2.title"
                                description="team.member.2.description"
                                partnerImgSrc={iconLinkedIn}
                                partnerImgLink={'https://www.linkedin.com/in/lukaszdmajewski/'}
                                partnerAltText="Partner Logo"
                            />
                        </div>
                    </main>
                </div>

                <div className="team-content-container">
                    <h2 className="team-title">{t('team.join')}</h2>
                    <div className="description-container">
                        <p className="description-text">{t('team.joinInfo')}</p>
                        <div className="join-button">
                            <NavLink to="/contact" className="nav-link">
                                {t('team.joinBtn')}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TeamPage;
