import { Link } from 'react-router-dom';
import './Products.css';
import { useTranslation } from 'react-i18next';
import productSrc0 from '../assets/img/product-scr-0.png';
import productSrc1 from '../assets/img/product-scr-1.png';
import productSrc2 from '../assets/img/product-scr-2.png';
import productSrc3 from '../assets/img/product-scr-3.png';
import productSmallRightArrow from '../assets/img/product-small-right-arrow.svg';

function ProductsPage() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <div className="modules-section">
                <div className="modules-title">{t('products.title')}</div>
                <div className="modules-grid-container">
                    <Link to="/products/genspace" className="product-link">
                        <div className={`module-content genspace ${i18n.language}`}>
                            <div className="module-left">
                                <div className="module-image">
                                    <img src={productSrc0} alt="Module" />
                                </div>
                            </div>
                            <div className="module-right">
                                <div className="module-text">
                                    <div className="module-description">
                                        <div className="module-title">{t('products.genspace.title')}</div>
                                        <div className="module-info">{t('products.genspace.desc')}</div>
                                    </div>
                                    <div className="module-learn-more">
                                        <div className="learn-more-text">
                                            <div className="learn-more-text">{t('products.more')}</div>
                                        </div>
                                        <img src={productSmallRightArrow} alt="Arrow" className="learn-more-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to="/products/budget-estimate" className="product-link">
                        <div className={`module-content budget-estimate ${i18n.language}`}>
                            <div className="module-left">
                                <div className="module-image">
                                    <img src={productSrc1} alt="Module" />
                                </div>
                            </div>
                            <div className="module-right">
                                <div className="module-text">
                                    <div className="module-description">
                                        <div className="module-title">{t('products.budget.title')}</div>
                                        <div className="module-info">{t('products.budget.desc')}</div>
                                    </div>
                                    <div className="module-learn-more">
                                        <div className="learn-more-text">{t('products.more')}</div>
                                        <img src={productSmallRightArrow} alt="Arrow" className="learn-more-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>

                    <Link to="/products/leasing" className="product-link">
                        <div className={`module-content leasing ${i18n.language}`}>
                            <div className="module-left">
                                <div className="module-image">
                                    <img src={productSrc2} alt="Module" />
                                </div>
                            </div>
                            <div className="module-right">
                                <div className="module-text">
                                    <div className="module-description">
                                        <div className="module-title">{t('products.leasing.title')}</div>
                                        <div className="module-info">{t('products.leasing.desc')}</div>
                                    </div>
                                    <div className="module-learn-more">
                                        <div className="learn-more-text">{t('products.more')}</div>
                                        <img src={productSmallRightArrow} alt="Arrow" className="learn-more-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link to="/products/management" className="product-link">
                        <div className={`module-content management ${i18n.language}`}>
                            <div className="module-left">
                                <div className="module-image">
                                    <img src={productSrc3} alt="Module" />
                                </div>
                            </div>
                            <div className="module-right">
                                <div className="module-text">
                                    <div className="module-description">
                                        <div className="module-title">{t('products.management.title')}</div>
                                        <div className="module-info">{t('products.management.desc')}</div>
                                    </div>
                                    <div className="module-learn-more">
                                        <div className="learn-more-text">{t('products.more')}</div>
                                        <img src={productSmallRightArrow} alt="Arrow" className="learn-more-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default ProductsPage;
