import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './MobileNavigation.css';
import sqareLogo from '../assets/img/sqare-logo-header.svg';
import sqareLogoWhite from '../assets/img/sqare-logo-white-header.svg';
import hamburger from '../assets/img/hamburger.svg';
import closeMenu from '../assets/img/close-menu.svg';
import { useTranslation } from 'react-i18next';
// import langChangeWhite from '../assets/img/lang-change-white.svg';

function MobileNavigation() {
    const { t, i18n } = useTranslation();
    const [property1, setProperty1] = useState('default');

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div
            className={`header ${property1}`}
            onClick={() => {
                if (property1.toString() === 'default') {
                    setProperty1('variant-2');
                } else {
                    setProperty1('default');
                }
            }}
        >
            {property1 === 'default' && (
                <>
                    <div className="header-navigation">
                        <div className="frame">
                            <div className="div">
                                <div className="frame-wrapper">
                                    <div className="sq-LOGO-wrapper">
                                        <NavLink to="/" className="nav-link">
                                            <img className="sq-LOGO" alt="Sq LOGO" src={sqareLogo} />
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="div-wrapper">
                                    <div className="frame-2">
                                        <div className="icon-menu-wrapper">
                                            <img className="open-menu" alt="open menu" src={hamburger} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {property1 === 'variant-2' && (
                <>
                    <div className="header-navigation-2">
                        <div className="frame">
                            <div className="div">
                                <div className="frame-3">
                                    <div className="frame-wrapper">
                                        <div className="img-wrapper">
                                            <img className="sq-LOGO" alt="Sq LOGO" src={sqareLogoWhite} />
                                        </div>
                                    </div>
                                    {/* <div className="frame-4">
                                        <img src={langChangeWhite} />
                                        <img className="vector" alt="Vector" src="vector.svg" />
                                    </div> */}
                                </div>
                                <div className="div-wrapper">
                                    <div className="frame-2">
                                        <div className="icon-menu-wrapper">
                                            <img className="close-menu" alt="close menu" src={closeMenu} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="frame-7">
                        <div className="frame-8">
                            <div className="frame-9">
                                <div className="frame-10">
                                    <div className="frame-11">
                                        <div className="text-wrapper">{t('navMobile.products.title')}</div>
                                        <div className="frame-12">
                                            <div className="frame-13">
                                                <div className="text-wrapper-2">
                                                    <NavLink to="/products/genspace" className="nav-link">
                                                        {t('navMobile.products.spaceplan')}
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="frame-13">
                                                <div className="text-wrapper-2">
                                                    <NavLink to="/products/budget-estimate" className="nav-link">
                                                        {t('navMobile.products.valuation')}
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="frame-13">
                                                <div className="text-wrapper-2">
                                                    <NavLink to="/products/leasing" className="nav-link">
                                                        {t('navMobile.products.process')}
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="frame-13">
                                                <div className="text-wrapper-2">
                                                    <NavLink to="/products/management" className="nav-link">
                                                        {t('navMobile.products.portfolio')}
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="frame-11">
                                        <div className="text-wrapper">{t('navMobile.company.title')}</div>
                                        <div className="frame-12">
                                            {/* <div className="frame-14">
                                                <div className="text-wrapper-2">About US</div>
                                            </div> */}
                                            <div className="frame-14">
                                                <div className="text-wrapper-2">
                                                    <NavLink to="/team" className="nav-link">
                                                        {t('navMobile.company.team')}
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="frame-14">
                                                <div className="text-wrapper-2">
                                                    {' '}
                                                    <NavLink to="/careers" className="nav-link">
                                                        {t('navMobile.company.careers')}
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="frame-11">
                                        <div className="text-wrapper">{t('navMobile.connect.title')}</div>
                                        <div className="frame-12">
                                            <div className="frame-15">
                                                <div className="text-wrapper-2">
                                                    <a href="https://sqarplan.com/#/login" className="nav-link">
                                                        {t('navMobile.connect.login')}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="frame-15">
                                                <div className="text-wrapper-2">
                                                    <NavLink to="/contact" className="nav-link">
                                                        {t('navMobile.connect.contact')}
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="frame-15">
                                                <div className="text-wrapper-2">
                                                    <a href="https://sqarplan.com/#/privacy-policy" className="nav-link">
                                                        {t('navMobile.connect.privacy')}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="frame-15">
                                                <div className="text-wrapper-2">
                                                    <a href="https://sqarplan.com/#/terms-of-use" className="nav-link">
                                                        {t('navMobile.connect.terms')}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mobile-lang-switch">
                                <div className="mobile-lang-items-wrapper">
                                    <div
                                        className={`mobile-lang-item ${i18n.language === 'en-EN' ? 'mobile-lang-item-selected' : ''}`}
                                        onClick={() => changeLanguage('en-EN')}
                                    >
                                        EN
                                    </div>
                                    <div
                                        className={`mobile-lang-item ${i18n.language === 'pl-PL' ? 'mobile-lang-item-selected' : ''}`}
                                        onClick={() => changeLanguage('pl-PL')}
                                    >
                                        PL
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default MobileNavigation;
