import * as React from 'react';
import './References.css';
import doubleQuote from '../assets/img/double-quote.svg';
import referencePhoto0 from '../assets/img/reference-photo-0.png';
import referencePhoto1 from '../assets/img/reference-photo-1.png';
import { useTranslation } from 'react-i18next';

function ClientReference({ imageSrc, name, position, testimonial, logoSrc, logoAlt }) {
    return (
        <div className="client-reference">
            <div className="client-info">
                <div className="client-image-wrapper">
                    <img loading="lazy" src={imageSrc} className="client-image" alt="Person" />
                    <div className="client-details">
                        <div className="client-name">{name}</div>
                        <div className="client-position">{position}</div>
                    </div>
                </div>
                <img loading="lazy" src={logoSrc} alt={logoAlt} className="client-logo" />
            </div>
            <div className="client-testimonial">{testimonial}</div>
        </div>
    );
}

function ReferencesPage() {
    const { t } = useTranslation();

    const references = [
        {
            imageSrc: referencePhoto0,
            name: t('references.0.name'),
            position: t('references.0.position'),
            testimonial: t('references.0.testimonial'),
            logoSrc: doubleQuote,
            logoAlt: 'Logo 1',
        },
        {
            imageSrc: referencePhoto1,
            name: t('references.1.name'),
            position: t('references.1.position'),
            testimonial: t('references.1.testimonial'),
            logoSrc: doubleQuote,
            logoAlt: 'Logo 2',
        },
    ];

    return (
        <>
            <div className="references-container">
                {/* <img loading="lazy" src="..." className="references-background-image" /> */}
                <div className="references-title">{t('references.title')}</div>
                <div className="references">
                    <div className="references-inner">
                        <div className="references-list">
                            {references.map((ref, index) => (
                                <ClientReference
                                    key={index}
                                    imageSrc={ref.imageSrc}
                                    name={ref.name}
                                    position={ref.position}
                                    testimonial={ref.testimonial}
                                    logoSrc={ref.logoSrc}
                                    logoAlt={ref.logoAlt}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ReferencesPage;
