import { Link } from 'react-scroll';
import ProductsPage from './Products';
import BenefitsPage from './Benefits';
import ReferencesPage from './References';
import ContactPage from './Contact';
import homeHero from '../assets/img/home-hero.svg';
import BookBtn from '../components/BookBtn';
import './Home.css';
import { Clients } from './Clients';
import { useTranslation } from 'react-i18next';

function HomePage() {
    const { t } = useTranslation();
    return (
        <>
            <section className="home-section">
                <div className="section-hero-container">
                    <div className="section-hero-left">
                        <div className="home-hero-title">
                            <span>{t('home.title')}</span>
                            <ul>
                                <li className="indented">{t('home.dot1')}</li>
                                <li className="indented">{t('home.dot2')}</li>
                                <li className="indented"> {t('home.dot3')}</li>
                            </ul>
                        </div>
                        <div className="home-hero-subtitle">{t('home.description')}</div>
                        <div className="hero-btn">
                            <Link to="contactPage" smooth={true} duration={500}>
                                <BookBtn stateProp="primary" noClick={true} buttonText={t('home.bookDemo')} />
                            </Link>
                        </div>
                    </div>
                    <div className="section-hero-right">
                        <img src={homeHero} alt="Hero" className="hero-image" />
                    </div>
                </div>
                <Clients />
                <ProductsPage />
                <BenefitsPage
                    className="benefits-1440-1253"
                    frameClassName="benefits-instance"
                    paragraphClassName="benefits-1440-1253-instance"
                    property1="frame-3720"
                />
                <ReferencesPage />
                <div id="contactPage">
                    <ContactPage />
                </div>
            </section>
        </>
    );
}

export default HomePage;
