import React, { useContext } from 'react';
import managementHero from '../assets/img/management-hero.svg';
import managementProcessMobileEN from '../assets/img/management-process-mobile_en.svg';
import managementProcessMobilePL from '../assets/img/management-process-mobile_pl.svg';
import managementProcessEN from '../assets/img/management-process_en.svg';
import managementProcessPL from '../assets/img/management-process_pl.svg';
import managementIconAdv0 from '../assets/img/management-icon-adv-0.svg';
import managementIconAdv1 from '../assets/img/management-icon-adv-1.svg';
import managementIconAdv2 from '../assets/img/management-icon-adv-2.svg';
import managementIconAdv3 from '../assets/img/management-icon-adv-3.svg';
import managementIconAdv4 from '../assets/img/management-icon-adv-4.svg';
import managementIconAdv5 from '../assets/img/management-icon-adv-5.svg';
import whiteBackground from '../assets/img/white-background.svg';
import HeroSection from '../components/HeroSection';

import { useTranslation } from 'react-i18next';
import './Management.css';

function ManagementPage() {
    const { t, i18n } = useTranslation();

    // managementProcessImgMap[i18n.language]
    const managementProcessImgMap = {
        'en-EN': managementProcessEN,
        'pl-PL': managementProcessPL,
    };

    // managementProcessMobileImgMap[i18n.language]
    const managementProcessMobileImgMap = {
        'en-EN': managementProcessMobileEN,
        'pl-PL': managementProcessMobilePL,
    };

    return (
        <>
            <div className="management-main-container">
                <HeroSection
                    title={t('management.title')}
                    description={t('management.description')}
                    buttonText={t('management.buttonText')}
                    heroImageSrc={managementHero}
                />
                <section className="management-process">
                    <h2 className="management-process__title">{t('management.process.title')}</h2>
                    <div className="management-process__steps">
                        <img
                            src={managementProcessMobileImgMap[i18n.language]}
                            alt={t('management.process.alt')}
                            className="management-process-image-mobile"
                        />
                        <img
                            src={managementProcessImgMap[i18n.language]}
                            alt={t('management.process.alt')}
                            className="management-process-image"
                        />
                    </div>
                </section>

                <section className="advantages">
                    <img loading="lazy" src={whiteBackground} alt="" className="advantages__bg-image" />
                    <h2 className="advantages__title">{t('management.advantages.title')}</h2>
                    <div className="advantages__content">
                        {[
                            {
                                imgSrc: managementIconAdv0,
                                alt: t('management.advantages.0.alt'),
                                text: t('management.advantages.0.text'),
                            },
                            {
                                imgSrc: managementIconAdv2,
                                alt: t('management.advantages.2.alt'),
                                text: t('management.advantages.2.text'),
                            },
                            {
                                imgSrc: managementIconAdv4,
                                alt: t('management.advantages.4.alt'),
                                text: t('management.advantages.4.text'),
                            },
                            {
                                imgSrc: managementIconAdv1,
                                alt: t('management.advantages.1.alt'),
                                text: t('management.advantages.1.text'),
                            },

                            {
                                imgSrc: managementIconAdv3,
                                alt: t('management.advantages.3.alt'),
                                text: t('management.advantages.3.text'),
                            },

                            {
                                imgSrc: managementIconAdv5,
                                alt: t('management.advantages.5.alt'),
                                text: t('management.advantages.5.text'),
                            },
                        ].map((item, idx) => (
                            <div key={idx} className="advantages__item">
                                <img loading="lazy" src={item.imgSrc} alt={item.alt} />
                                <span className="advantages__item-text">{item.text}</span>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </>
    );
}

export default ManagementPage;
