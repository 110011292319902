export function waitFor(condition, interval = 200) {
    return new Promise((resolve) => {
        (function checkCondition() {
            if (condition()) {
                resolve();
            } else {
                setTimeout(checkCondition, interval);
            }
        })();
    });
}
