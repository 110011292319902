import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import './PopUp.css';
import BookBtn from './BookBtn';

const PopUpClickEvents = {
    PopUp: 'PopUp',
    CloseIcon: 'CloseIcon',
    Outside: 'Outside',
};

const PopUpSizes = {
    contentBased: 'contentBased',
    small: 'small',
    mediumSmall: 'mediumSmall',
    medium: 'medium',
    big: 'big',
};

const PopUp = forwardRef(
    (
        {
            size = PopUpSizes.contentBased,
            title,
            icon,
            iconColor,
            noPadding,
            closeOnOutsideClick = true,
            background = 'white',
            empty = false,
            children,
            onPopUpClick,
            onClosePopUp,
        },
        ref
    ) => {
        console.log('Indise-PopUp');
        const [isVisible, setIsVisible] = useState(false);

        useImperativeHandle(ref, () => ({
            show() {
                setIsVisible(true);
            },
            hide() {
                setIsVisible(false);
            },
        }));

        const handleClickInside = (event) => {
            console.log('handleClickInside', { event });
            onPopUpClick(PopUpClickEvents.PopUp);
            const clickInsidePopupEvent = new CustomEvent('clickInsidePopup', { bubbles: true, detail: event });
            document.dispatchEvent(clickInsidePopupEvent);
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        };

        const handleClickClose = (event) => {
            console.log('handleClickClose');
            onPopUpClick(PopUpClickEvents.CloseIcon);
            hide();
            onClosePopUp();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        };

        const handleClickOutside = (event) => {
            console.log('handleClickOutside', { closeOnOutsideClick });
            onPopUpClick(PopUpClickEvents.Outside);
            hide();
            onClosePopUp();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
        };

        const show = () => {
            setIsVisible(true);
        };

        const hide = () => {
            setIsVisible(false);
        };

        return (
            <div className={`popUp-container ${!isVisible ? 'hide' : ''}`} onClick={handleClickOutside}>
                <div>
                    {!empty ? (
                        <div className={`popUp-window ${noPadding ? 'popUp-no-padding' : ''} ${size}`} onClick={handleClickInside}>
                            <div className={`popUp-header ${noPadding ? 'popUp-padding' : ''}`}>
                                <div className="popUp-title-container">
                                    <div className="popUp-title-icon" style={{ color: iconColor }}>
                                        {icon && <img src={icon} alt="" />}
                                    </div>
                                    <p className="popUp-title">{title}</p>
                                </div>
                                <div onClick={handleClickClose} className="pop-up-close-icon">
                                    X
                                </div>
                            </div>
                            <div className="popUp-message">{children}</div>
                            <div className="popUp-OkBtn" onClick={handleClickClose}>
                                <BookBtn buttonText="Ok" stateProp="primary" noClick={true} />
                            </div>
                        </div>
                    ) : (
                        <div className={`popUp-window popUp-no-padding-all ${size}`} style={{ background }}>
                            <div className="popUp-message">{children}</div>
                            <div className="popUp-OkBtn">
                                <BookBtn buttonText="Ok" stateProp="primary" noClick={true} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

PopUp.propTypes = {
    size: PropTypes.oneOf(Object.values(PopUpSizes)),
    title: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    noPadding: PropTypes.bool,
    closeOnOutsideClick: PropTypes.bool,
    background: PropTypes.string,
    empty: PropTypes.bool,
    notRemoveFromDOM: PropTypes.bool,
    children: PropTypes.node,
    onPopUpClick: PropTypes.func,
    onClosePopUp: PropTypes.func,
};

export default PopUp;
