import React, { useContext } from 'react';
import './Estimates.css';
import estimatesHero from '../assets/img/budget-estimates-hero.svg';
import estimatesProcessPL from '../assets/img/budget-estimate-process_pl.svg';
import estimatesProcessEN from '../assets/img/budget-estimate-process_en.svg';
import estimatesProcessMobilePL from '../assets/img/budget-estimate-process-mobile_pl.svg';
import estimatesProcessMobileEN from '../assets/img/budget-estimate-process-mobile_en.svg';
import estimatesHowWorksPL from '../assets/img/budget-estimate-how-works_pl.svg';
import estimatesHowWorksEN from '../assets/img/budget-estimate-how-works_en.svg';
import estimatesHowWorksMobilePL from '../assets/img/budget-estimate-how-works-mobile_pl.svg';
import estimatesHowWorksMobileEN from '../assets/img/budget-estimate-how-works-mobile_en.svg';
import HeroSection from '../components/HeroSection';
import { useTranslation } from 'react-i18next';

function EstimatesPage() {
    const { t, i18n } = useTranslation();

    const estimatesProcessImgMap = {
        'en-EN': estimatesProcessEN,
        'pl-PL': estimatesProcessPL,
    };

    const estimatesProcessMobileImgMap = {
        'en-EN': estimatesProcessMobileEN,
        'pl-PL': estimatesProcessMobilePL,
    };

    const estimatesHowWorksImgMap = {
        'en-EN': estimatesHowWorksEN,
        'pl-PL': estimatesHowWorksPL,
    };

    const estimatesHowWorksMobileImgMap = {
        'en-EN': estimatesHowWorksMobileEN,
        'pl-PL': estimatesHowWorksMobilePL,
    };

    return (
        <>
            <div className="budget-estimate-container">
                <HeroSection
                    title={t('estimates.top.title')}
                    description={t('estimates.top.info')}
                    buttonText={t('estimates.top.btn')}
                    heroImageSrc={estimatesHero}
                />
                <div className="page-wrapper">
                    <h2 className="estimates-section-title">{t('estimates.process.title')}</h2>
                    <img
                        loading="lazy"
                        src={estimatesProcessMobileImgMap[i18n.language]}
                        alt={t('estimates.process.img.alt')}
                        className="process-image-mobile"
                    />
                    <img
                        loading="lazy"
                        src={estimatesProcessImgMap[i18n.language]}
                        alt={t('estimates.process.img.alt')}
                        className="process-image"
                    />
                </div>
                <section className="how-it-works-section">
                    <div className="how-it-works-container">
                        <h2 className="est-how-it-works-title">{t('estimates.how')}</h2>
                        <img
                            loading="lazy"
                            src={estimatesHowWorksMobileImgMap[i18n.language]}
                            alt={t('estimates.how.img.alt')}
                            className="estimates-how-it-works-image-mobile"
                        />

                        <img
                            loading="lazy"
                            src={estimatesHowWorksImgMap[i18n.language]}
                            alt={t('estimates.how.img.alt')}
                            className="estimates-how-it-works-image"
                        />
                    </div>
                </section>
            </div>
        </>
    );
}

export default EstimatesPage;
