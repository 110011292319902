import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import MainNavigation from '../components/MainNavigation';
import MobileNavigation from '../components/MobileNavigation';
import Footer from '../components/Footer';
import './Root.css';
import { MobileContext } from '../providers/MobileContext';

function RootLayout() {
    const isMobile = useContext(MobileContext);

    return (
        <>
            {isMobile ? <MobileNavigation property1="variant-2" /> : <MainNavigation />}
            <main className="content">
                <div className="root-container">
                    <Outlet />
                </div>
            </main>
            <div className="footer-wrapper">
                <Footer />
            </div>
        </>
    );
}

export default RootLayout;
