import React, { useContext } from 'react';
import './Leasing.css';
import leasingHero from '../assets/img/leasing-hero.svg';
import leasingProcessPL from '../assets/img/leasing-process_pl.svg';
import leasingProcessEN from '../assets/img/leasing-process_en.svg';
import leasingProcessMobilePL from '../assets/img/leasing-process-mobile_pl.svg';
import leasingProcessMobileEN from '../assets/img/leasing-process-mobile_en.svg';
import leasingHowWorksPL from '../assets/img/leasing-how-works_pl.svg';
import leasingHowWorksEN from '../assets/img/leasing-how-works_en.svg';
import leasingHowWorksMobilePL from '../assets/img/leasing-how-works-mobile_pl.svg';
import leasingHowWorksMobileEN from '../assets/img/leasing-how-works-mobile_en.svg';
import leasingIconAdv0 from '../assets/img/leasing-icon-adv-0.svg';
import leasingIconAdv1 from '../assets/img/leasing-icon-adv-1.svg';
import leasingIconAdv2 from '../assets/img/leasing-icon-adv-2.svg';
import leasingIconAdv3 from '../assets/img/leasing-icon-adv-3.svg';
import leasingIconAdv4 from '../assets/img/leasing-icon-adv-4.svg';
import leasingIconAdv5 from '../assets/img/leasing-icon-adv-5.svg';
import HeroSection from '../components/HeroSection';
import backgroundWhite from '../assets/img/white-background.svg';
import { useTranslation } from 'react-i18next';

function LeasingPage() {
    const { t, i18n } = useTranslation();

    const leasingProcessImgMap = {
        'en-EN': leasingProcessEN,
        'pl-PL': leasingProcessPL,
    };

    const leasingProcessMobileImgMap = {
        'en-EN': leasingProcessMobileEN,
        'pl-PL': leasingProcessMobilePL,
    };

    const leasingHowWorksImgMap = {
        'en-EN': leasingHowWorksEN,
        'pl-PL': leasingHowWorksPL,
    };

    const leasingHowWorksMobileImgMap = {
        'en-EN': leasingHowWorksMobileEN,
        'pl-PL': leasingHowWorksMobilePL,
    };

    const advantages = [
        {
            description: t('leasing.advantages.0.description'),
            imageSrc: leasingIconAdv0,
            imageAlt: t('leasing.advantages.0.imageAlt'),
        },
        {
            description: t('leasing.advantages.1.description'),
            imageSrc: leasingIconAdv1,
            imageAlt: t('leasing.advantages.1.imageAlt'),
        },
        {
            description: t('leasing.advantages.2.description'),
            imageSrc: leasingIconAdv2,
            imageAlt: t('leasing.advantages.2.imageAlt'),
        },
        {
            description: t('leasing.advantages.3.description'),
            imageSrc: leasingIconAdv3,
            imageAlt: t('leasing.advantages.3.imageAlt'),
        },
        {
            description: t('leasing.advantages.4.description'),
            imageSrc: leasingIconAdv4,
            imageAlt: t('leasing.advantages.4.imageAlt'),
        },
        {
            description: t('leasing.advantages.5.description'),
            imageSrc: leasingIconAdv5,
            imageAlt: t('leasing.advantages.5.imageAlt'),
        },
    ];

    return (
        <>
            <div className="leasing-main-container">
                <HeroSection
                    title={t('leasing.title')}
                    description={t('leasing.description')}
                    buttonText={t('leasing.buttonText')}
                    heroImageSrc={leasingHero}
                />

                <section className="leasing-section ">
                    <div className="content-container-leasing">
                        <h2 className="leasing-section-title">{t('leasing.process.title')}</h2>
                        <div className="step-container">
                            <img
                                src={leasingProcessMobileImgMap[i18n.language]}
                                alt={t('leasing.process.img.alt')}
                                className="leasing-process-image-mobile"
                            />
                            <img
                                src={leasingProcessImgMap[i18n.language]}
                                alt={t('leasing.process.img.alt')}
                                className="leasing-process-image"
                            />
                        </div>
                    </div>
                </section>

                <section className="section-with-bg">
                    <img src={backgroundWhite} alt="" className="bg-image" />
                    <div className="leasing-content-container bg-content">
                        <h2 className="leasing-advantage-title">{t('leasing.advantages.title')}</h2>
                        <div className="leasing-advantages-container margin-top-40">
                            {advantages.map((advantage, index) => (
                                <div key={index} className="leasing-advantage">
                                    <img src={advantage.imageSrc} alt={advantage.imageAlt} className="leasing-advantage-image" />
                                    <p className="leasing-advantage-description margin-top-15">{advantage.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="leasing-section leasing-no-border">
                    <header className="content-container-leasing-how">
                        <h2 className="how-it-works-title">{t('leasing.how')}</h2>
                        <img
                            src={leasingHowWorksMobileImgMap[i18n.language]}
                            alt={t('leasing.howAImg.alt')}
                            className="how-it-works-image-mobile"
                        />

                        <img src={leasingHowWorksImgMap[i18n.language]} alt={t('leasing.howAImg.alt')} className="how-it-works-image" />
                    </header>
                </section>
            </div>
        </>
    );
}

export default LeasingPage;
