import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useReducer } from 'react';
import './Benefits.css';
import heroTimePL from '../assets/img/hero-time_pl.svg';
import heroTimeEN from '../assets/img/hero-time_en.svg';
import heroTimeMobilePL from '../assets/img/hero-time-mobile_pl.svg';
import heroTimeMobileEN from '../assets/img/hero-time-mobile_en.svg';
import { MobileContext } from '../providers/MobileContext';
import { useTranslation } from 'react-i18next';

function BenefitsPage({ property1, className, frameClassName, paragraphClassName }) {
    const [state, dispatch] = useReducer(reducer, {
        property1: property1 || 'frame-3720',
    });
    const isMobile = useContext(MobileContext);
    const { t, i18n } = useTranslation();

    const heroTimeMobileImgMap = {
        'en-EN': heroTimeMobileEN,
        'pl-PL': heroTimeMobilePL,
    };

    const heroTimeImgMap = {
        'en-EN': heroTimeEN,
        'pl-PL': heroTimePL,
    };

    // {imageMap[i18n.language]}

    function reducer(state, action) {
        if (state.property1 === 'frame-3720') {
            switch (action) {
                case 'click':
                    return {
                        property1: 'frame-3725',
                    };

                case 'mouse_enter':
                    return {
                        property1: 'variant-6',
                    };

                case 'click_30':
                    return {
                        property1: 'frame-3724',
                    };

                case 'mouse_enter_30':
                    return {
                        property1: 'variant-9',
                    };
                default:
                    return state;
            }
        }

        if (state.property1 === 'variant-6') {
            switch (action) {
                case 'mouse_leave':
                    return {
                        property1: 'frame-3720',
                    };

                case 'click':
                    return {
                        property1: 'frame-3725',
                    };
                default:
                    return state;
            }
        }

        if (state.property1 === 'variant-9') {
            switch (action) {
                case 'mouse_leave_30':
                    return {
                        property1: 'frame-3720',
                    };

                case 'click_30':
                    return {
                        property1: 'frame-3724',
                    };
                default:
                    return state;
            }
        }

        if (state.property1 === 'frame-3724') {
            switch (action) {
                case 'click_95':
                    return {
                        property1: 'frame-3720',
                    };

                case 'mouse_enter_95':
                    return {
                        property1: 'variant-8',
                    };

                case 'click':
                    return {
                        property1: 'frame-3725',
                    };

                case 'mouse_enter':
                    return {
                        property1: 'variant-5',
                    };
                default:
                    return state;
            }
        }

        if (state.property1 === 'variant-8') {
            switch (action) {
                case 'mouse_leave_95':
                    return {
                        property1: 'frame-3724',
                    };

                case 'click_95':
                    return {
                        property1: 'frame-3720',
                    };
                default:
                    return state;
            }
        }

        if (state.property1 === 'variant-5') {
            switch (action) {
                case 'mouse_leave':
                    return {
                        property1: 'frame-3724',
                    };

                case 'click':
                    return {
                        property1: 'frame-3725',
                    };
                default:
                    return state;
            }
        }

        if (state.property1 === 'frame-3725') {
            switch (action) {
                case 'click_95':
                    return {
                        property1: 'frame-3720',
                    };

                case 'mouse_enter_95':
                    return {
                        property1: 'variant-4',
                    };

                case 'click_30':
                    return {
                        property1: 'frame-3724',
                    };

                case 'mouse_enter_30':
                    return {
                        property1: 'variant-7',
                    };
                default:
                    return state;
            }
        }

        if (state.property1 === 'variant-4') {
            switch (action) {
                case 'mouse_leave_95':
                    return {
                        property1: 'frame-3725',
                    };

                case 'click_95':
                    return {
                        property1: 'frame-3720',
                    };
                default:
                    return state;
            }
        }

        if (state.property1 === 'variant-7') {
            switch (action) {
                case 'mouse_leave_30':
                    return {
                        property1: 'frame-3725',
                    };

                case 'click_30':
                    return {
                        property1: 'frame-3724',
                    };
                default:
                    return state;
            }
        }

        return state;
    }

    BenefitsPage.propTypes = {
        property1: PropTypes.oneOf([
            'frame-3724',
            'variant-5',
            'variant-4',
            'variant-8',
            'variant-7',
            'variant-6',
            'variant-9',
            'frame-3725',
            'frame-3720',
        ]),
    };

    return (
        <>
            <div className="benefits-title-container">
                <div className="benefits-title">{t('benefits.title')}</div>
                {/* src={isMobile ? heroTimeMobile : heroTime} */}
                <img
                    loading="lazy"
                    src={isMobile ? heroTimeMobileImgMap[i18n.language] : heroTimeImgMap[i18n.language]}
                    className="benefits-responsive-image"
                    alt="Descriptive text"
                />
            </div>
            <div className={`benefits ${className}`}>
                <div className="text-wrapper">{t('benefits.segment')}</div>
                <div className="frame">
                    <div className="benefits-header">
                        <div
                            className={`paragraph-wrapper ${state.property1} ${frameClassName}`}
                            onMouseEnter={() => {
                                dispatch('mouse_enter_95');
                            }}
                            onClick={() => {
                                dispatch('click_95');
                            }}
                            onMouseLeave={() => {
                                dispatch('mouse_leave_95');
                            }}
                        >
                            <div className={`paragraph ${paragraphClassName}`}>{t('benefits.developers')}</div>
                        </div>
                        <div
                            className={`div-wrapper property-1-0-${state.property1}`}
                            onMouseEnter={() => {
                                dispatch('mouse_enter');
                            }}
                            onClick={() => {
                                dispatch('click');
                            }}
                            onMouseLeave={() => {
                                dispatch('mouse_leave');
                            }}
                        >
                            <div className="paragraph-2">{t('benefits.architects')}</div>
                        </div>
                        <div
                            className={`frame-2 property-1-2-${state.property1}`}
                            onMouseEnter={() => {
                                dispatch('mouse_enter_30');
                            }}
                            onClick={() => {
                                dispatch('click_30');
                            }}
                            onMouseLeave={() => {
                                dispatch('mouse_leave_30');
                            }}
                        >
                            <div className="paragraph-3">{t('benefits.tenants')}</div>
                        </div>
                    </div>
                    <div className={`frame-3 property-1-4-${state.property1}`}>
                        <div className="frame-wrapper">
                            <div className="frame-4">
                                <div className="paragraph-4">
                                    {['frame-3720', 'variant-6', 'variant-9'].includes(state.property1) && (
                                        <>{t('benefits.developers.leasing')}</>
                                    )}

                                    {['frame-3724', 'variant-5', 'variant-8'].includes(state.property1) && (
                                        <>{t('benefits.tenants.selection')}</>
                                    )}

                                    {['frame-3725', 'variant-4', 'variant-7'].includes(state.property1) && (
                                        <>{t('benefits.architects.design')}</>
                                    )}
                                </div>
                                <div className="paragraph-5">
                                    {['frame-3720', 'variant-6', 'variant-9'].includes(state.property1) && (
                                        <p className="p">{t('benefits.developers.leasing.info')}</p>
                                    )}

                                    {['frame-3724', 'variant-5', 'variant-8'].includes(state.property1) && (
                                        <p className="p">{t('benefits.tenants.selection.info')}</p>
                                    )}

                                    {['frame-3725', 'variant-4', 'variant-7'].includes(state.property1) && (
                                        <p className="p">{t('benefits.architects.design.info')}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="frame-5">
                            <div className="frame-4">
                                <div className="paragraph-4">
                                    {['frame-3720', 'variant-6', 'variant-9'].includes(state.property1) && (
                                        <>{t('benefits.developers.vaccancy')}</>
                                    )}

                                    {['frame-3724', 'variant-5', 'variant-8'].includes(state.property1) && (
                                        <>{t('benefits.tenants.process')}</>
                                    )}

                                    {['frame-3725', 'variant-4', 'variant-7'].includes(state.property1) && (
                                        <>{t('benefits.architects.communication')}</>
                                    )}
                                </div>
                                <div className="paragraph-5">
                                    {['frame-3720', 'variant-6', 'variant-9'].includes(state.property1) && (
                                        <p className="p">{t('benefits.developers.vaccancy.info')}</p>
                                    )}

                                    {['frame-3724', 'variant-5', 'variant-8'].includes(state.property1) && (
                                        <p className="p">{t('benefits.tenants.process.info')}</p>
                                    )}

                                    {['frame-3725', 'variant-4', 'variant-7'].includes(state.property1) && (
                                        <p className="p">{t('benefits.architects.communication.info')}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="frame-6">
                            <div className="frame-4">
                                <div className="paragraph-4">
                                    {['frame-3720', 'variant-6', 'variant-9'].includes(state.property1) && (
                                        <>{t('benefits.developers.doc')}</>
                                    )}

                                    {['frame-3724', 'variant-5', 'variant-8'].includes(state.property1) && (
                                        <>{t('benefits.tenants.orderlyProcess')}</>
                                    )}

                                    {['frame-3725', 'variant-4', 'variant-7'].includes(state.property1) && (
                                        <>{t('benefits.architects.focus')}</>
                                    )}
                                </div>
                                <div className="paragraph-5">
                                    {['frame-3720', 'variant-6', 'variant-9'].includes(state.property1) && (
                                        <p className="p">{t('benefits.developers.doc.info')}</p>
                                    )}

                                    {['frame-3724', 'variant-5', 'variant-8'].includes(state.property1) && (
                                        <p className="p">{t('benefits.tenants.orderlyProcess.info')}</p>
                                    )}

                                    {['frame-3725', 'variant-4', 'variant-7'].includes(state.property1) && (
                                        <p className="p">{t('benefits.architects.focus.info')}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BenefitsPage;
