import * as React from 'react';
import './Footer.css';
import { NavLink, useNavigate } from 'react-router-dom';
import sqarLogoWhite from '../assets/img/sqare-logo-white-header.svg';
import sqarMail from '../assets/img/sqar-mail.svg';
import linkedinLogo from '../assets/img/linkedin-logo.svg';
import newsletterBtn from '../assets/img/newsletter-button.svg';
import { useTranslation } from 'react-i18next';

const CustomNavLink = ({ to, children, ...props }) => {
    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();

        const isExternal = to.startsWith('http');

        if (isExternal) {
            window.location = to;
        } else {
            window.scrollTo(0, 0);
            setTimeout(() => navigate(to), 0);
        }
    };

    return (
        <NavLink to={to} onClick={handleClick} {...props}>
            {children}
        </NavLink>
    );
};

const InfoColumn = ({ title, items }) => {
    const { t } = useTranslation();

    return (
        <div className="info-column">
            <div className="info-title">{t(title)}</div>
            <div className="info-items">
                {items.map((item, index) => (
                    <div key={index} className="info-item">
                        <CustomNavLink to={item.url} className="nav-link">
                            {t(item.name)}
                        </CustomNavLink>
                    </div>
                ))}
            </div>
        </div>
    );
};

function Footer() {
    const { t } = useTranslation();

    const products = [
        { name: 'footer.products.spaceplan', url: '/products/genspace' },
        { name: 'footer.products.valuation', url: '/products/budget-estimate' },
        { name: 'footer.products.process', url: '/products/leasing' },
        { name: 'footer.products.portfolio', url: '/products/management' },
    ];
    const company = [
        { name: 'footer.company.team', url: '/team' },
        { name: 'footer.company.careers', url: '/careers' },
    ];
    const help = [
        { name: 'footer.help.contact', url: '/contact' },
        { name: 'footer.help.privacy', url: 'https://portal.sqarplan.com/privacy-policy' },
        { name: 'footer.help.terms', url: 'https://portal.sqarplan.com/#/terms-of-use' },
    ];

    return (
        <>
            <div className="footer-outer-container">
                <div className="footer-container">
                    <div className="footer-content">
                        <div className="footer-main">
                            <div className="footer-col">
                                <div className="company-info">
                                    <div className="company-logo">
                                        <img alt="Company Logo" src={sqarLogoWhite} />
                                    </div>
                                    <div className="company-details">
                                        <div>
                                            {t('footer.addressA')}
                                            <br />
                                            {t('footer.addressB')}
                                        </div>
                                        <a className="footer-link-mail" href="mailto:contact@squareplan.io">
                                            <div className="contact">
                                                <img alt="" src={sqarMail} />
                                                <div className="contact-email">{t('footer.emailLabel')}</div>
                                            </div>
                                        </a>
                                    </div>
                                    <a href="https://www.linkedin.com/company/sqar-plan">
                                        <img className="linkedin-img" alt="" src={linkedinLogo} />
                                    </a>
                                </div>
                            </div>
                            <div className="footer-col">
                                <InfoColumn title="footer.products.title" items={products} />
                            </div>
                            <div className="footer-col">
                                <InfoColumn title="footer.company.title" items={company} />
                            </div>
                            <div className="footer-col">
                                <InfoColumn title="footer.help.title" items={help} />
                            </div>
                            <div className="footer-col newsletter">
                                <div className="newsletter-label">{t('footer.subscribe')}</div>
                                <form className="newsletter-form">
                                    <input
                                        type="email"
                                        id="newsletter"
                                        className="newsletter-input"
                                        placeholder={t('footer.type')}
                                        aria-label="Enter your email"
                                    />
                                    <div className="newsletter-button">
                                        <img alt="newsletter button" src={newsletterBtn} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div>{t('footer.privacy')}</div>
                    <div>
                        {t('footer.rights')} {new Date().getFullYear()}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
