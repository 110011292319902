import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { MobileProvider } from './providers/MobileProvider';
import HomePage from './pages/Home';
import ProductsPage from './pages/Products';
import RootLayout from './pages/Root';
import './style.css';
import GenspacePage from './pages/Genspace';
import EstimatesPage from './pages/Estimates';
import LeasingPage from './pages/Leasing';
import ManagementPage from './pages/Management';
import TeamPage from './pages/Team';
import CareersPage from './pages/Careers';
import ContactPage from './pages/Contact';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        children: [
            { path: '/', element: <HomePage /> },
            { path: '/products', element: <ProductsPage /> },
            { path: '/products/genspace', element: <GenspacePage /> },
            { path: '/products/budget-estimate', element: <EstimatesPage /> },
            { path: '/products/leasing', element: <LeasingPage /> },
            { path: '/products/management', element: <ManagementPage /> },
            { path: '/team', element: <TeamPage /> },
            { path: '/careers', element: <CareersPage /> },
            { path: '/contact', element: <ContactPage /> },
        ],
    },
]);

function oldSqarplanLinksRedirect() {
    const urlBase = process.env.REACT_APP_OLD_LINK_REDIRECTION_BASE;
    const url = window.location.href;
    const parts = url.split('/#/');

    // Redirection if old link (link which contains: "/#/" after domain)
    if (parts.length === 2 && parts[0] === window.location.origin) {
        const newUrl = urlBase + '/' + parts[1];
        window.location.href = newUrl;
    }
}

// Confer 2-char lang code to 5-char e.g. 'en' to 'en-EN'
function convertLanguageCodeTo5Char(code) {
    if (code.length !== 2) {
        return code;
    }
    return `${code}-${code.toUpperCase()}`;
}

function App() {
    oldSqarplanLinksRedirect();

    const { i18n } = useTranslation();

    // useEffect(() => {
    //     const validLanguages = ['pl-PL', 'en-EN'];
    //     const storedLang = localStorage.getItem('i18nextLng');
    //     console.log({ validLanguages, storedLang });
    //     if (!validLanguages.includes(storedLang)) {
    //         let userDefaultLanguage = navigator.language || navigator.userLanguage;

    //         userDefaultLanguage = convertLanguageCodeTo5Char(userDefaultLanguage);

    //         if (!validLanguages.includes(userDefaultLanguage)) userDefaultLanguage = 'en-EN';

    //         console.log('Invalid language set as default - change to ', userDefaultLanguage);

    //         localStorage.setItem('i18nextLng', userDefaultLanguage);
    //         i18n.changeLanguage(userDefaultLanguage);
    //     }
    // }, [i18n]);
   
    // temp set EN only
    useEffect(() => {
            localStorage.setItem('i18nextLng', 'en-EN');
            i18n.changeLanguage('en-EN');
    }, [i18n]);

    return (
        <MobileProvider>
            <RouterProvider router={router} />
        </MobileProvider>
    );
}

export default App;
