import React, { useState, useEffect } from 'react';
import './Clients.css';
import mapletree from '../assets/img/companies-slider/mapletree.svg';
import jll from '../assets/img/companies-slider/JLL.svg';
import strabag from '../assets/img/companies-slider/strabag.svg';
import vastint from '../assets/img/companies-slider/vastint.svg';
import epp from '../assets/img/companies-slider/EPP.svg';
import ghelamco from '../assets/img/companies-slider/ghelamco.svg';
import muniak from '../assets/img/companies-slider/muniak.svg';
import probuild from '../assets/img/companies-slider/probuild.svg';
import { useTranslation } from 'react-i18next';

export const Clients = () => {
    const images = [
        mapletree,
        jll,
        strabag,
        vastint,
        epp,
        ghelamco,
        muniak,
        probuild,
        mapletree,
        jll,
        strabag,
        vastint,
        epp,
        ghelamco,
        muniak,
        probuild,
        mapletree,
        jll,
        strabag,
        vastint,
        epp,
        ghelamco,
        muniak,
        probuild,
    ];

    const [current, setCurrent] = useState(0);
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrent((prev) => (prev + 1) % 20);
        }, 2000);

        return () => clearInterval(timer);
    }, []);

    const { t } = useTranslation();

    return (
        <>
            <div className="clients-section">
                <div className="clients-container">
                    <div className="clients-title">{t('home.ourClients')}</div>
                    <div className="clients-slider-container">
                        <div className="slider-container" style={{ transform: `translateX(-${(current * 225)}px)` }}>
                            {images.map((img, idx) => (
                                <img key={idx} src={img} alt={`slide ${idx}`} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
