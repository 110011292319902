import React, { useContext } from 'react';
import genspaceBottomHero from '../assets/img/genspace-bottom-hero.svg';
import genspaceHero from '../assets/img/genspace-hero.svg';
import genspaceHowWorksMobilePL from '../assets/img/genspace-how-works-mobile_pl.svg';
import genspaceHowWorksMobileEN from '../assets/img/genspace-how-works-mobile_en.svg';
import genspaceHowWorksPL from '../assets/img/genspace-how-works_pl.svg';
import genspaceHowWorksEN from '../assets/img/genspace-how-works_en.svg';
import genspaceIconTime from '../assets/img/genspace-icon-time.svg';
import genspaceIconCost from '../assets/img/genspace-icon-cost.svg';
import genspaceIconAuto from '../assets/img/genspace-icon-auto.svg';
import genspaceIconEff from '../assets/img/genspace-icon-efficiency.svg';
import genspaceIconWork from '../assets/img/genspace-icon-work.svg';
import whiteBackground from '../assets/img/white-background.svg';
import HeroSection from '../components/HeroSection';
import { useTranslation } from 'react-i18next';
import './Genspace.css';

function Advantage({ imageUrl, altText, description }) {
    return (
        <div className="advantage">
            <img loading="lazy" src={imageUrl} alt={altText} className="advantage-icon" />
            <span className="advantage-text">{description}</span>
        </div>
    );
}

function GenspacePage() {
    const { t, i18n } = useTranslation();

    const genspaceHowWorksMobileImgMap = {
        'en-EN': genspaceHowWorksMobileEN,
        'pl-PL': genspaceHowWorksMobilePL,
    };

    const genspaceHowWorksImgMap = {
        'en-EN': genspaceHowWorksEN,
        'pl-PL': genspaceHowWorksPL,
    };

    const advantages1 = [
        {
            imageUrl: genspaceIconTime,
            altText: 'genspace.advantages.time.alt',
            description: 'genspace.advantages.time',
        },
        {
            imageUrl: genspaceIconCost,
            altText: 'genspace.advantages.cost.alt',
            description: 'genspace.advantages.cost',
        },
        {
            imageUrl: genspaceIconAuto,
            altText: 'genspace.advantages.auto.alt',
            description: 'genspace.advantages.auto',
        },
    ];

    const advantages2 = [
        {
            imageUrl: genspaceIconEff,
            altText: 'genspace.advantages.efficiency.alt',
            description: 'genspace.advantages.efficiency',
        },
        {
            imageUrl: genspaceIconWork,
            altText: 'genspace.advantages.work.alt',
            description: 'genspace.advantages.work',
        },
    ];

    return (
        <>
            <div className="genspace-main-container">
                <HeroSection
                    title={t('genspace.topSection.title')}
                    description={t('genspace.topSection.description')}
                    buttonText={t('genspace.topSection.buttonText')}
                    heroImageSrc={genspaceHero}
                />

                <div className="highlight-section">
                    <div className="content-wrapper">
                        <img
                            loading="lazy"
                            src={whiteBackground}
                            alt={t('genspace.advantages.background.alt')}
                            className="genspace-background-image"
                        />
                        <div className="section-title advantage-section-title">{t('genspace.advantages.title')}</div>
                        <div className="advantage-list">
                            <div className="advantage-group">
                                <div className="advantage-section">
                                    {advantages1.map((advantage, index) => (
                                        <Advantage
                                            key={index}
                                            imageUrl={advantage.imageUrl}
                                            altText={t(advantage.altText)}
                                            description={t(advantage.description)}
                                        />
                                    ))}

                                    {advantages2.map((advantage, index) => (
                                        <Advantage
                                            key={index}
                                            imageUrl={advantage.imageUrl}
                                            altText={t(advantage.altText)}
                                            description={t(advantage.description)}
                                        />
                                    ))}
                                </div>
                                {/* <div className="advantage-section advantage-bottom">
                                    
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-section">
                    <div className="genspace-content-container">
                        <div className="section-heading">{t('genspace.how')}</div>
                        <img
                            loading="lazy"
                            src={genspaceHowWorksMobileImgMap[i18n.language]}
                            alt={t('genspace.howImg.alt')}
                            className="wide-image-mobile"
                        />

                        <img
                            loading="lazy"
                            src={genspaceHowWorksImgMap[i18n.language]}
                            alt={t('genspace.howImg.alt')}
                            className="wide-image"
                        />
                    </div>
                </div>
                <div className="two-column">
                    <div className="column-left">
                        <div className="section-block">
                            <div className="genspace-section-content">
                                <div className="section-text">
                                    <div className="section-title-large">{t('genspace.upload.title')}</div>
                                    <div className="section-description">{t('genspace.upload.info')}</div>

                                    <div className="action-button" role="button" tabIndex="0">
                                        {t('genspace.upload.btn')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column-right">
                        <img
                            loading="lazy"
                            src={genspaceBottomHero}
                            alt={t('genspace.upload.background.alt')}
                            className="full-width-imagerrr"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default GenspacePage;
